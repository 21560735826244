import { IEndpoint } from '../types'

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan-sqa1.uap.jpnmob-acyan.npr.aws.asurion.net/api',
  searchLogEnvironment: 'SQAD365',
  kibanaIndex: 'uap-acyan-api-sqa1',
  kibanaGQLIndex: 'uap-acyan-cluster-sqa1',
  kibanaLink: 'https://gk-common-oss.japan.npr.aws.asurion.net/_dashboards',
}
