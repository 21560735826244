import { IActiveConfig, IConstants, IEndpoint } from '../types'

export const defaultConstants: IConstants = {
  displayErrors: true,
  displayDetailedErrors: true,
  defaultUrl: 'claim/information',
  allowLegacySearch: true,
  kibanaIndex: 'uap-acyan-prd-api',
  kibanaGQLIndex: 'uap-acyan-prd-cluster',
  kibanaLink: 'https://gk-opensearch.japan.prd.aws.asurion.net/_dashboards',
}

export const defaultEndpointConfig: IEndpoint = {
  baseLegacyURL: 'https://wqanprkiiwb001v.int.asurion.com:5001/api',
  baseURL: 'https://acyan.ucp.jpnmob-acyan.prd.aws.asurion.net/api',
}

export const config: IActiveConfig = {
  authConfig: {
    domainName: 'asurion-uap-acyan-prd-pool',
    region: 'ap-northeast-1',
    userPoolWebClientId: '2mroo3ukv5mdjip2h87p27l0l8',
    userPoolId: 'ap-northeast-1_btTUUBO3S',
    identityPoolId: '',
    identityProvider: 'acyan-prd-SSO',
  },
  constants: defaultConstants,
  endpoints: [
    {
      key: 'prd',
      value: defaultEndpointConfig,
    },
  ],
}
